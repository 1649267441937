<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage {{ typeName }}</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{ capitalizeTypeName }}
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="__add()" class="mt-4 btn btn-primary mr-1" dark>
                  <i class="fa fa-plus"></i>
                  Add {{ typeName }}
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <v-row>
                <v-col>
                  <v-btn-toggle
                    dense
                    mandatory
                    v-model="search.status"
                    @change="getAllGeneralRequest"
                  >
                    <v-btn value="received">
                      <span class="hidden-sm-and-down">Received</span>

                      <v-icon right> fas fa-comment-dots text-warning </v-icon>
                    </v-btn>

                    <v-btn value="approved">
                      <span class="hidden-sm-and-down">Approved</span>

                      <v-icon right> fas fa-check-square text-success </v-icon>
                    </v-btn>

                    <v-btn value="rejected">
                      <span class="hidden-sm-and-down">Rejected</span>

                      <v-icon right> fas fa-window-close text-danger </v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </div>
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="4" md="3">
                  <v-text-field
                    clearable
                    dense
                    v-on:keyup.enter="getAllGeneralRequest()"
                    label="Title"
                    outlined
                    v-model="search.title"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="3">
                  <v-text-field
                    clearable
                    dense
                    v-on:keyup.enter="getAllGeneralRequest()"
                    label="Search by CRN, name, email, phone"
                    outlined
                    v-model="search.name"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="getAllGeneralRequest()"
                    :items="academicYears"
                    :loading="loading"
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Batch"
                    outlined
                    v-model="search.academic_year_id"
                  >
                  </v-select>
                </v-col>

                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="getAllGeneralRequest()"
                    :items="levels"
                    :loading="loading"
                    @change="getPrograms(search.level_id)"
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Level"
                    outlined
                    v-model="search.level_id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="getAllGeneralRequest()"
                    :items="programs"
                    :loading="loading"
                    @change="getGrades(search.program_id)"
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Program"
                    outlined
                    v-model="search.program_id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="getAllGeneralRequest()"
                    :items="gradesLevels"
                    :loading="loading"
                    @change="
                      getAcademicClasses(
                        search.academic_year_id,
                        search.program_id,
                        search.grade_id
                      )
                    "
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Grade"
                    outlined
                    v-model="search.grade_id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="2" md="3">
                  <v-select
                    v-on:keyup.enter="getAllGeneralRequest()"
                    :items="academic_classes"
                    :loading="loading"
                    clearable
                    dense
                    item-text="title"
                    item-value="id"
                    label="Classes"
                    outlined
                    v-model="search.academic_class_id"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <v-btn @click="getAllGeneralRequest()" class="btn btn-primary btn-search">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon
                    >&nbsp; Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loadingData">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loadingData" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loadingData">
                <thead>
                  <tr class="px-3">
                    <th>Request title</th>
                    <th>Requested by</th>
                    <th>Academic info</th>
                    <th class="px-2">Status</th>
                    <th class="pr-3 text-center" v-if="search.status === 'received'">Action</th>
                  </tr>
                </thead>
                <tr
                  v-show="generalRequests.length > 0"
                  :key="index"
                  v-for="(item, index) of generalRequests"
                >
                  <td class="px-3">

                    <a
                        @click="__viewDetail(item)"
                        class="mr-2 cursor-pointer"
                    >
                      {{ item.title }}
                    </a>
                    <div class="mt-2">
                      <span v-if="item.category">
                        <span
                          class="badge badge-secondary ml-2 text-capitalize"
                        >
                          {{ item.category }}
                        </span>
                      </span>
                    </div>

                  </td>
                  <td>
                    <div class="text-secondary">
                      <span
                        class="font-weight-medium"
                        v-if="item.student_full_name"
                    >
                        <a
                            @click="openSummaryPage(item)"
                            class="text-primary font-weight-bolder text-hover-primary mb-1"
                        >
                          {{ item.student_full_name }}
                        </a>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div v-if="item.student_setting">
                      <div class="text-secondary">
                        <b>Student ID: </b>{{ item.student_personal_id }}
                      </div>
                      <div class="text-secondary">
                        <b>Academic year: </b>{{ item.student_setting.academic_year || 'N/A' }} <br />
                      </div>
                      <div class="text-secondary">
                        <b>Program: </b> {{ item.student_setting.program_title || 'N/A' }} <br />
                      </div>
                      <div class="text-secondary">
                        <b>Class: </b>{{ item.student_setting.current_class_title || 'N/A' }}
                      </div>
                      <div class="text-secondary">
                        <b>Grade/Semester: </b>{{ item.student_setting.current_semester_title || 'N/A' }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <template v-if="item.status">
                      <span
                        :class="badgeStatus(item.status)"
                        class="text-capitalize badge"
                      >
                        {{  item.status }}
                      </span>
                    </template>
                  </td>

                  <td class="pr-0 text-center" v-if="search.status === 'received'">
                    <b-dropdown
                      no-caret
                      no-flip
                      right
                      size="sm"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      variant="link"
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i> </span
                        ></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">

                        <b-dropdown-text
                          class="navi-item"
                          tag="div"
                          v-if="item.status == 'received'"
                        >
                          <a
                            @click="__detail(item, 'approved')"
                            class="navi-link"
                            href="#"
                          >
                            <span class="navi-icon">
                              <i class="far fa-check-square text-success"></i>
                            </span>
                            <span class="navi-text"> Approve request</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text
                          class="navi-item"
                          tag="div"
                          v-if="item.status == 'received'"
                        >
                          <a
                            @click="__detail(item, 'rejected')"
                            class="navi-link"
                            href="#"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-window-close text-danger"></i>
                            </span>
                            <span class="navi-text"> Decline request </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text
                          class="navi-item"
                          tag="div"
                          v-if="item.status == 'received'"
                        >
                          <a
                            @click="__edit(item)"
                            class="navi-link"
                            href="#"
                          >
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit </span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text
                          class="navi-item"
                          tag="div"
                          v-if="item.status == 'received'"
                        >
                          <a
                            @click="__delete(item.id)"
                            class="navi-link"
                            href="#"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </td>
                </tr>
                <tr v-if="generalRequests.length == 0">
                  <td colspan="5" class="text-center">
                    <strong>Data not available</strong>
                  </td>
                </tr>
              </table>
              <b-pagination
                v-if="generalRequests.length > 0"
                class="pull-right mt-7"
                :per-page="perPage"
                :total-rows="total"
                @input="getAllGeneralRequest"
                first-number
                last-number
                v-model="page"
              ></b-pagination>
            </div>
          </div>
          <template>
            <v-row>
              <v-dialog
                max-width="800px"
                scrollable
                v-model="dialog"
              >
                <v-card v-if="generalRequestDetail">
                  <v-toolbar flat>
                    <v-card-title>
                      General request detail
                      <hr />
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="__reset">
                        <i class="fas fa-times"></i>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <div class="col-md-6">
                          <h4>
                            {{ generalRequestDetail.student_full_name }} <br />
                            {{ generalRequestDetail.student_type }}
                          </h4>
                          <h4>{{ generalRequestDetail.title }}</h4>
                          <p v-html="generalRequestDetail.description"></p>
                          <v-row
                              v-if="
                              generalRequestDetail.attachment_1 ||
                              generalRequestDetail.attachment_2 ||
                              generalRequestDetail.attachment_3
                            "
                          >
                            <v-col cols="12"> Attachments </v-col>
                            <v-col cols="12">
                              <v-list-item-group color="primary">
                                <v-list-item v-if="generalRequestDetail.attachment_1">
                                  <v-list-item-icon>
                                    <v-icon> mdi-file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <a
                                          :href="generalRequestDetail.attachment_one_path['real']"
                                          target="_blank"
                                      >Attachment -
                                        {{ generalRequestDetail.attachment_1 }}</a
                                      >
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="generalRequestDetail.attachment_2">
                                  <v-list-item-icon>
                                    <v-icon> mdi-file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <a
                                          :href="generalRequestDetail.attachment_two_path['real']"
                                          target="_blank"
                                      >Attachment -
                                        {{ generalRequestDetail.attachment_2 }}</a
                                      >
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="generalRequestDetail.attachment_3">
                                  <v-list-item-icon>
                                    <v-icon> mdi-file</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <a
                                          :href="generalRequestDetail.attachment_three_path['real']"
                                          target="_blank"
                                      >Attachment -
                                        {{ generalRequestDetail.attachment_3 }}</a
                                      >
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-col>
                          </v-row>

                        </div>
                        <div class="col-md-6">
                          <v-row>
                            <v-col cols="12">
                              <label>Remarks on Approval/Disapproval <span class="text-danger">*</span></label>
                              <ckeditor
                                :config="editorConfig"
                                id="description"
                                v-model="generalRequestDetail.remarks"
                              ></ckeditor>
                              <span class="text-danger" v-if="$v.generalRequestDetail.remarks.$error">This field is required</span>
                            </v-col>
<!--                            <v-col cols="12">-->
<!--                              <v-select-->
<!--                                :items="statuses"-->
<!--                                dense-->
<!--                                item-text="title"-->
<!--                                item-value="value"-->
<!--                                label="Approval Status"-->
<!--                                outlined-->
<!--                                v-model="generalRequestDetail.status"-->
<!--                              >-->
<!--                              </v-select>-->
<!--                            </v-col>-->
                          </v-row>
                        </div>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-divider></v-divider>
                    <v-col class="text-right" cols="12">
                      <v-btn
                        @click="__reset"
                        class="btn btn-standard cancel-btn"
                        depressed
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        :loading="isBusy"
                        @click="__update"
                        class="text-white ml-2 btn btn-primary"
                        depressed
                      >
                        Save
                      </v-btn>
                    </v-col>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog max-width="800px" v-model="viewDialog">
                <v-card v-if="generalRequestDetail">
                  <v-toolbar dark>
                    <v-card-title class="headline">
                      General request status
                      <hr />
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="viewDialog = false">
                        <i class="fas fa-times"></i>
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text style="height: 500px">
                    <v-container>
                      <v-row v-if="generalRequestDetail && generalRequestDetail.id">
                        <v-col>

                          <div class="my-3 text-secondary h6">
                            <span class="font-size-lg font-weight-bolder">Title: </span>{{ generalRequestDetail.title }}
                          </div>

                          <div class="my-3">
                            <span class=" text-secondary h6">Description: </span>
                            <span class="font-size-lg font-weight-bold"  v-html="generalRequestDetail.description"></span>
                          </div>

                          <div class="my-3 text-secondary h6">
                            <span class="font-size-lg font-weight-bolder">Student ID: </span>{{ generalRequestDetail.student_personal_id }}
                          </div>

                          <div class="my-3 text-secondary h6">
                            <span class="font-size-lg font-weight-bold">Student Name: </span>
                            <a
                              @click="openSummaryPage(generalRequestDetail)"
                              class="text-primary font-weight-bold text-hover-primary mb-1"
                            >
                              {{ generalRequestDetail.student_full_name }}
                            </a>
                          </div>

                          <div class="my-3 text-secondary h6">
                            <span class="font-size-lg font-weight-bold">Academic year: </span>{{ generalRequestDetail.student_setting.academic_year || 'N/A' }} <br />
                          </div>

                          <div class="my-3 text-secondary h6">
                            <span class="font-size-lg font-weight-bold">Program: </span> {{ generalRequestDetail.student_setting.program_title || 'N/A' }} <br />
                          </div>

                          <div class="my-3 text-secondary h6">
                            <span class="font-size-lg font-weight-bold">Class: </span>{{ generalRequestDetail.student_setting.current_class_title || 'N/A' }}
                          </div>

                          <div class="my-3 text-secondary h6">
                            <span class="font-size-lg font-weight-bold">Grade/Semester: </span>{{ generalRequestDetail.student_setting.current_semester_title || 'N/A' }}
                          </div>

                          <div
                              class="text-secondary h6 mt-2"
                              v-if="generalRequestDetail.status"
                          >
                            <span class="font-weight-bold">Status : </span>
                            <span
                                :class="badgeStatus(generalRequestDetail.status)"
                                class="badge text-capitalize"
                            >
                              {{  generalRequestDetail.status }}
                            </span>
                          </div>

                          <div class="my-3" v-if="generalRequestDetail.formatted_approved_date">
                            <span class="h6">Approved date: </span>
                            <span  class="font-size-lg font-weight-bold">{{generalRequestDetail.formatted_approved_date}}</span>
                          </div>

                          <div class="my-3" v-if="generalRequestDetail.approvedBy">
                            <span class="h6">Approved by: </span>
                            <span  class="font-size-lg font-weight-bold">{{generalRequestDetail.approvedBy}}</span>
                          </div>

                          <div class="my-3" v-if="generalRequestDetail.formatted_rejected_date">
                            <span class="text-secondary h6">Rejected date: </span>
                            <span class="font-size-lg font-weight-bold">{{generalRequestDetail.formatted_rejected_date}}</span>
                          </div>

                          <div class="my-3" v-if="generalRequestDetail.rejectedBy">
                            <span class="text-secondary h6">Rejected by: </span>
                            <span class="font-size-lg font-weight-bold">{{generalRequestDetail.rejectedBy}}</span>
                          </div>

                          <div class="my-3">
                            <span class="text-secondary h6">Remarks: </span>
                            <span class="font-size-lg font-weight-bold" v-if="generalRequestDetail.remarks" v-html="generalRequestDetail.remarks"></span>
                            <p v-else>N/A</p>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row
                          v-if="
                              generalRequestDetail.attachment_1 ||
                              generalRequestDetail.attachment_2 ||
                              generalRequestDetail.attachment_3
                            "
                      >
                        <v-col cols="12"> Attachments </v-col>
                        <v-col cols="12">
                          <v-list-item-group color="primary">
                            <v-list-item v-if="generalRequestDetail.attachment_1">
                              <v-list-item-icon>
                                <v-icon> mdi-file</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <a
                                      :href="generalRequestDetail.attachment_one_path['real']"
                                      target="_blank"
                                  >Attachment -
                                    {{ generalRequestDetail.attachment_1 }}</a
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="generalRequestDetail.attachment_2">
                              <v-list-item-icon>
                                <v-icon> mdi-file</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <a
                                      :href="generalRequestDetail.attachment_two_path['real']"
                                      target="_blank"
                                  >Attachment -
                                    {{ generalRequestDetail.attachment_2 }}</a
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="generalRequestDetail.attachment_2">
                              <v-list-item-icon>
                                <v-icon> mdi-file</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <a
                                      :href="generalRequestDetail.attachment_three_path['real']"
                                      target="_blank"
                                  >Attachment -
                                    {{ generalRequestDetail.attachment_2 }}</a
                                  >
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-2 cancel-btn"
                      text
                      x-large
                      @click="viewDialog = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </template>
          <create-and-update
            @refresh="getAllGeneralRequest"
            ref="create-and-update"
          ></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate.vue";
import FilterMixin from "@/mixins/FilterMixin";
import GeneralRequestService from "@/core/services/general-request/GeneralRequestService";
import {required} from "vuelidate/lib/validators";

const generalRequest = new GeneralRequestService();
export default {
  name: "general-request",
  mixins: [FilterMixin],
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      statuses: [
        { title: "Approved", value: "approved" },
        { title: "Rejected", value: "rejected" },
      ],
      generalRequests: [],
      page: null,
      generalRequestDetail: {},
      attrs: false,
      loadingData: false,
      isBusy: false,
      startDate: false,
      endDate: false,
      dialog: false,
      viewDialog: false,
      perPage: null,
      total: null,
      status: null,
      search: {
        type: "received",
        status: "received",
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            "Bold",
            "Italic",
            "Underline",
            "Strike",
            "RemoveFormat",
            "Link",
            "NumberedList",
            "BulletedList",
          ],
        ],
      },
    };
  },
  validations: {
    generalRequestDetail: {
      remarks: {required},
    }
  },
  mounted() {
    this.search.type = this.$route.params.type || '';
    this.getAllGeneralRequest();
    this.getAcademicYears();
    this.getLevels();
    this.getAllPrograms();
  },
  computed: {
    type() {
      return this.$route.params.type || '';
    },
    typeName() {
      let type = this.$route.params.type || '';
      return type.replace('_', ' ');
    },
    capitalizeTypeName() {
      return this.typeName.charAt(0).toUpperCase() + this.typeName.slice(1);
    },
    routeTab() {
        return {
          title: this.capitalizeTypeName,
        };
    },
  },
  methods: {
    badgeStatus(status) {
      if (status) {
        switch (status) {
          case "received":
            return "badge-warning";
          case "approved":
            return "badge-success";
          case "rejected":
            return "badge-danger";
        }
      } else {
        return "badge-warning";
      }
    },
    getAllGeneralRequest() {
      this.loadingData = true;
      generalRequest
        .paginate(this.search)
        .then((response) => {
          this.generalRequests = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    __add() {
      this.$refs["create-and-update"].showModal();
    },
    __edit(item) {
      this.$refs["create-and-update"].showModal(item);
    },

    __detail(item, status = "received") {
      this.dialog = true;
      Object.assign(this.generalRequestDetail, item)
      this.generalRequestDetail.status = status;
    },
    __viewDetail(generalRequest) {
      this.viewDialog = true;
      this.generalRequestDetail = generalRequest;
    },
    __reset() {
      this.dialog = false;
      this.generalRequestDetail = {};
    },
    __update() {
      this.$v.generalRequestDetail.$touch();
      if (this.$v.generalRequestDetail.$error) {
        setTimeout(() => {
          this.$v.generalRequestDetail.$reset();
        }, 3000);
      } else {
        this.isBusy = true;
        generalRequest
            .approvedOrDeclined(this.generalRequestDetail.id, this.generalRequestDetail)
            .then((response) => {
              if(response.data.status == "OK") {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.__reset();
                this.getAllGeneralRequest();
              }
            })
            .catch(() => {
              this.isBusy = false;
            })
            .finally(() => {
              this.isBusy = false;
            });
      }
    },

    resetFilter() {
      this.search = {};
      this.search.status = "received";
      this.getAllGeneralRequest();
    },
    __delete(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            generalRequest
              .delete(id)
              .then((response) => {
                this.$snotify.success("Successfully deleted");
                this.getAllGeneralRequest();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    openSummaryPage(item) {
      this.$router.push({
        name: "students-summary",
        params: {
          id: item.student_id,
          type: item.student_type,
        },
      });
    },
  },
};
</script>
<style scoped>
.badge-danger {
  color: #ffffff !important;
  background-color: #F64E60 !important;
}
</style>
